<template>
    <div class="h-100">
        <div id="container-input-login"
             class="h-100 d-flex flex-column justify-content-center align-items-center pb-md-16 pb-0">

            <v-form ref="form"
                    class="standard-form"
                    v-model="valid"
                    @submit.prevent="validateForm">
                <v-alert border="left" dense
                         color="#0b62a4" text
                         :class="showMessageError ? 'mb-2' : 'mb-10'">
                    <div class="d-flex align-items-center">
                        <v-img :src="sgp" class="mr-3" max-height="30px" max-width="30px" contain></v-img>
                        O Tobias Informador é integrado com o SGP. Faça login utilizando as suas credenciais do sistema.
                    </div>
                </v-alert>

                <v-alert border="left" dense
                         type="error" text
                         class="mb-10"
                         v-if="showMessageError">
                    {{errorMessage}}
                </v-alert>

                <InputComponent label="Usuário SGP"
                                v-model="formModel.Email"
                                :rules="[rules.requiredRule, rules.userSGPRule]"
                                :error="errorLogin"
                                @input="errorLogin = false"/>

                <InputComponent label="Senha"
                                v-model="formModel.Password"
                                forgotPassword
                                :rules="[rules.requiredRule, rules.passwordRule]"
                                :error="errorLogin"
                                sensive
                                @input="errorLogin = false"/>

                <v-btn :style="styleButton"
                       elevation="0"
                       class="btn w-100 mt-8"
                       :loading="loading"
                       type="submit">Entrar</v-btn>

                <div class="d-flex justify-content-center mt-3">
                    <p class="label-cta-cadastro-login">
                        Não tem uma conta ainda? <br><router-link class="underlined" to="/cadastro">Crie uma</router-link>
                    </p>
                </div>
            </v-form>
        </div>
        <div id="container-image" class="avoid-click">
            <img id="geometric-login" :src="geometric" />
        </div>
    </div>
</template>

<script>
    import rules from '../utils/rules';
    import sgp from '../assets/icon-sgp.png';
    import geometric from "../assets/geometric-login.svg";

    import InputComponent from "../components/Input.vue";

    export default {
        name: "Login",

        components: {
            InputComponent,
        },

        data() {
            return {
                formModel: {
                    Email: '',
                    Password: '',
                },
                rules,
                sgp,
                geometric,
                loading: false,
                valid: false,
                errorLogin: false,
                showMessageError: false,
                styleButton: {
                    backgroundColor: this.$colors.primary,
                    color: this.$colors.grays.white,

                },
                errorMessage: "E-mail e/ou senha inválidos."
            }
        },

        methods: {
            validateForm: function () {
                this.loading = true;
                if (this.$refs.form.validate()) {
                    this.handleLogin()
                }
            },

            handleLogin: function () {
                const Email = this.formModel.Email.endsWith("@pge.se.gov.br") ?
                    this.formModel.Email :
                    this.formModel.Email + "@pge.se.gov.br";
                const Password = this.formModel.Password;
                this.$store.dispatch('login', { Email, Password })
                    .then((res) => {
                        if (res.data.success) {
                            this.errorLogin = false;
                            this.showMessageError = false;
                            this.$router.push('/informes')
                        } else {
                            this.errorMessage = res.data.message
                            this.errorLogin = true;
                            this.showMessageError = true;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                        this.errorLogin = true;
                        this.showMessageError = true;
                    })

            },
        },

        mounted() {
            if (this.$route.params && this.$route.params.formModel) {
                Object.assign(this.formModel, this.$route.params.formModel)
            }
        }
    }
</script>

<style>
    #container-image {
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;
        z-index: 0;
    }

    #geometric-login {
        width: 120%;
        height: auto;
        position: absolute;
        bottom: -25%;
        left: -10%;
    }

    .label-cta-cadastro-login{
        text-align: center;
        font-size: 1.1rem;
    }

    .underline {
        font-weight: 500;
        color: #269D4F;
        text-decoration: underline;
    }

    @media (max-width: 960px) {
        #geometric-login {
            display: none;
        }
    }
</style>
